<script>

import { required } from "vuelidate/lib/validators";

import {} from "@/state/helpers";

export default {
	components: {
		
	},
	data() {
		return {
			submitted:false,
		};
	},
	validations: {
		membership: {
			name: {
				required,
			},
		},
	},
	methods: {
		
		onValidateMembership(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return false;
			}

			return true;
		},
  },
	mounted() {
		
	},
	props: {
		membership : {
			type: Object
		}
	},
};
</script>

<template>
	<div>
		<div class="row">
			<div class="col">
				<div class="mb-3">
					<label>Nombre*</label>
					<input
						v-model="membership.name"
						type="text"
						class="form-control"
						:class="{
							'is-invalid': submitted && $v.membership.name.$error,
						}"
					/>
					<div
						v-if="submitted && !$v.membership.name.required"
						class="invalid-feedback">
							El nombre es requerido
					</div>
				</div>
			</div>
		</div>
    <div class="row">
			<div class="col">
				<div class="mb-3">
					<label>Puntos acumulados*</label>
					<b-form-input type="number" v-model="membership.points"/>
				</div>
			</div>
		</div>
    <div class="row">
			<div class="col">
        <b-form-group
          class="mb-3"
          label-cols-sm="1"
          label-cols-lg="1"
          label="Color"
          label-for="color">
          <b-form-input
            v-model="membership.color"
            class="form-control-color mw-100"
            id="color"
            type="color"
            name="color"
          ></b-form-input>
        </b-form-group>
			</div>
		</div>
	</div>
</template>

<style scoped>

</style>