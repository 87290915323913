<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _debounce from 'lodash.debounce';
import EmptyList from "@/components/widgets/empty_list"
import Membership from "@/components/loyalty/new-membership"

import {
} from "@/state/helpers";

export default {
  page: {
    title: "Memberships",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    EmptyList,
    Membership
  },
  data() {
    return {
      title: "Memberships",
      items: [],
			currentPage:1,
			perPage:10,
      rows:0,
			isLoading:false,
      memberships:[],
      confirmDelete:false,
      membership: {},
      empty_list_config:{
          "title" : 'No se encontraron afiliaciones',
          "linkButtonText" : this.$t("common.reset_search"),
          icon: "fa-cubes"
      },
      empty_config:{
          "title" : 'No se encontraron afiliaciones',
          "subtitle" : 'Aún no has creado niveles. Comienza creando una nueva.',
          "buttonText" : 'Nueva Afiliación',
          icon: "fa-cubes"
      },
        showNewMembershipModal: false,
        isLocal: window.config.env == 'dev'
    };
  },
  created(){
		this.debounceMemberships = _debounce(this.searchMemberships.bind(this), 1000);
	},
	mounted(){
		this.loadMemberships();
  },
  methods:{
    onRemoveMembership(data){
      this.membership = data;
      this.confirmDelete = true;
    },
    onConfirmRemoveMembershipClicked(){
      let loader = this.$loading.show();
      this.currentPage=1;
        this.loadMemberships();
      this.$notify({ type: 'success', text: this.$t('personalizations.delete_personalization_success'),title:  this.$t('personalizations.title') });
      loader.hide();
    },

		loadMemberships(){
			
      let loader = this.$loading.show();
      this.isLoading = true;
      
      this.memberships = [
        {
          _id: '1',
          name: 'Gold',
          members: 102339,
          status: 1,
          points: 1000
        },
        {
          _id: '2',
          name: 'Silver',
          members: 72123,
          status: 1,
          points: 500
        },
        {
          _id: '3',
          name: 'Bronze',
          members: 50120,
          status: 1,
          points: 0
        },
        {
          _id: '4',
          name: 'VIP',
          status: 0
        },
      ]
      this.isLoading = false;
      loader.hide();
    },
    searchMemberships(query){
      this.query = query;
			/*let loader = this.$loading.show();
      this.isLoading = true;
      this.query = query;
      this.empty_list_config.subtitle =  this.$t("memberships.empty_search_text").replaceAll('#', this.query);

      if(query){
        this.memberships=[];
        this.currentPage = 1;
				const params={
					q: `where[project]=${localStorage.getItem("current_project")}&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}`,
				}

        this.getContents(params).then((contents)=>{
          if(contents&& contents.data){
            this.personalizations= contents.data;
            this.rows= contents.totalCount
          }
          this.isLoading = false;
          loader.hide();
        });
      }else{
        this.isLoading = false;
        this.currentPage = 1;
        loader.hide();
        this.loadPersonalizations();
      }*/
    },
		onMembershipListPageClicked(){
			this.loadMemberships();
		},	
    onEditMembershipClicked(data){
      this.membership = data;
      this.showNewMembershipModal = true;
    },
    
    onResetSearchClicked(){
      this.query = '';
      this.loadMemberships();
    },
    onNewMembershipClicked(){
      this.membership = {}
      this.showNewMembershipModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Niveles" :items="items" />
    <div class="row">
      <div class = "col">
        <div class="card mb-3">
				<div class="card-body">
          <div class="row ">
            <div class="col-sm-4 d-inline-flex">
                <div class="search-box me-2 mb-0 d-inline-block">
                  <div class="position-relative">
                      <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('common.search')"
                      @input="debounceMemberships($event.target.value)"
                      v-model="query"
                      />
                      <i class="bx bx-search-alt search-icon"></i>
                  </div>
                </div>
            </div>
            <div class="col text-sm-end">
              <button
                      type="button"
                      class="btn btn-primary mb-0 me-0"
                      @click="onNewMembershipClicked">
                  <i class="mdi mdi-plus me-1"></i> Nuevo Nivel
              </button>
            </div>
          </div>
          <EmptyList :config="empty_list_config" v-if="!isLoading && memberships.length == 0 && query!=''" @onButtonClicked="onResetSearchClicked" class="mt-3 mb-3"/>
          <EmptyList :config="empty_config" v-if="!isLoading && memberships.length == 0 && query ==''" @onButtonClicked="onNewMembershipClicked" class="mt-3 mb-3"/>
        </div>
          <div class="col-lg-12" v-if="!isLoading && memberships.length>0">
            <div>
              <div class="table-responsive">
                <table class="table align-middle table-nowrap table-hover mb-0">
                  <thead class="table-light">
                    <tr>
                      <th scope="col">Nombre</th>
                      <th scope="col">Puntos acumulados</th>
                      <th scope="col">Miembros</th>
                      <th scope="col">Status</th>

                      <th scope="col" class="text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="data in memberships" :key="data._id">
                      <td>
                        <h5 class="text-truncate font-size-14 mb-0 interact" v-on:click="onEditMembershipClicked(data)">
                          <a class="text-dark">{{
                            data.name
                          }}</a>
                        </h5>
                      </td>
                      <td>
												{{ data.status ==1 ? data.points : '-'}}
											</td>
                      <td>
												{{ data.members|| '-' }}
											</td>
											<td>
                        <span class="badge font-size-10"
                          :class="{
                          'bg-success': `${data.status}` == 1,
                          'bg-warning': `${data.status}` == 0}">
                            {{ data.status == 1 ? 'Activo' : 'Inactivo' }}
                        </span>
											</td>
                      <td class="text-end">
                        <i class="fas text-primary me-2 interact" :class="data.status == 1 ? 'fa-pause' : 'fa-play'"></i>
                        <i class="fas fa-edit text-success me-2 interact" v-on:click="onEditMembershipClicked(data)"></i>
                        <i class="fas fa-trash-alt text-danger me-1 interact" v-on:click="onRemoveMembership(data)"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="rows > perPage">
          <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          @input="onMembershipListPageClicked"
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                      ></b-pagination>
                  </ul>
              </div>
          </div>
        </div>
        <b-modal v-model="confirmDelete" id="modal-center" centered :title="$t('common.confirm')" title-class="font-18" @ok="onConfirmRemoveMembershipClicked"  :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')">
            <p>Estás seguro que deseas eliminar este nivel?</p>
        </b-modal>
        <b-modal
          v-model = "showNewMembershipModal"
          title-class="font-18"
          :okTitle="$t('common.confirm')"  :cancelTitle="$t('common.cancel')"
          :title="membership?._id ? 'Editar Nivel' : 'Nuevo Nivel'">
          <Membership :membership="membership"></Membership>
        </b-modal>
      </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
</style>
